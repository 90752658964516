<template>
  <div v-if="item && item.id" class="zt-page-content">
      <div class="zt-block">
          <div style="padding:30px;">
                <div class="head flex">
                    <div class="flex-1">询价申请及应答</div>
                    <div style="font-size:16px;color:#A9B6E1;font-weight:400;">
                        <div v-if="item.state==1">待确认</div>
                        <div v-if="item.state==2">已应答</div>
                    </div>
                </div>
                <div class="list">
                    <div class="flex">
                        <div class="title">订购企业</div>
                        <div>{{item.company}}</div>
                    </div>
                    <div class="flex">
                        <div class="title">询价人</div>
                        <div>{{item.name}}</div>
                    </div>
                    <div class="flex">
                        <div class="title">手机号</div>
                        <div>{{item.mobile}}</div>
                    </div>
                    <div class="flex" v-for="(item1,idx1) in item.entries" :key="idx1">
                        <div class="title">商品信息{{idx1+1}}</div>
                        <div class="item-right">
                            <div class="flex">
                                <div class="title">商品名称</div>
                                <div>{{item1.item.name}}</div>
                            </div>
                            <div class="flex spec">
                                <div class="title">商品规格</div>
                                <div>{{item1.itemSku.material.name}}</div>
                                <div>{{item1.itemSku.color.name}} </div>
                                <div v-if="item1.itemSku.catAttrValues && item1.itemSku.catAttrValues.length>0">
                                    <div v-for="(val,v) in item1.itemSku.catAttrValues" :key="v">{{val.value}}</div>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="title">订购数量</div><div>{{item1.count}}</div>
                            </div>
                            <div class="flex">
                                <div class="title" :style="item.state==1 ? 'line-height:40px' : ''">出售单价</div>
                                <div v-if="item.state>1" class="price" style="font-size:16px;" :style="item.state==1 ? 'line-height:40px' : ''">￥{{item1.price}}</div>
                                <div v-if="item.state==1" class="price" style="font-size:16px">
                                    <el-input v-model.number="item1.price" type="number" min="0" placeholder="请输入内容"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="title">询价日期</div>
                        <div>{{item.createdAt | moment('YYYY/MM/DD')}}</div>
                    </div>
                    <div class="flex">
                        <div class="title">付款方式</div>
                        <div v-if="item.payType==0">一次性付清</div>
                        <div v-if="item.payType==1">首付30%，尾款70%</div>
                    </div>
                    <div class="flex">
                        <div class="title">期望到货时间</div>
                        <div>{{item.hopeAt | moment('YYYY/MM/DD')}}</div>
                    </div>
                    <div v-if="item.totalPrice>0" class="flex">
                        <div class="title">总价</div>
                        <div v-if="item.totalPrice" class="price">￥{{item.totalPrice}}</div>
                    </div>

                </div>

          </div>

      </div>
      <div v-if="item.state==1" class="zt-block" style="padding:20px 0;text-align:center;">
            <el-button @click="back()" style="width: 75px;margin-right:30px" size="small" type="primary" plain>取消</el-button>
            <el-button @click="submitForm()" style="width: 120px;" size="small" type="primary">确定并应答</el-button>
      </div>

  </div>
</template>

<script>
import { clone } from 'lodash';
import { IMG_URL_PRE } from "@/config";
import { getEnquiry, enquiryOffer } from "@/service/mobile";

export default {
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: "",
      item: null,
    };
  },
  created() {
    this.refreshItems();
  },
  methods: {
    back() {
        this.$router.go(-1);
    },
    refreshItems() {
      getEnquiry(this.$route.params.id).then(rst => {
          if (rst) {
            rst.totalPrice = rst.entries.reduce((totalPrice, item) => totalPrice + (item.price*item.count), 0)
            this.item = rst;
          }
          console.log(this.item);
        }).catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    submitForm() {
        let _item = clone(this.ruleForm);
        let flagArr = [];
        this.item.entries.forEach((e,idx) => {
            if (!e.price) {
                flagArr = flagArr.concat(false);
            } else {
                flagArr = flagArr.concat(true);
            }
        });
        if (flagArr.indexOf(false)>-1) {
            this.$message.error('请填写报价');
        } else {
            this.submit();
        }
    },
    submit() {
        let ruleForm = {
            id:this.item.id,
            entries:[],
        };
        this.item.entries.forEach((e,idx) => {
            ruleForm.entries.push({id:e.id,price:e.price})
        });
        console.log(ruleForm);
        enquiryOffer(ruleForm).then(rst => {
          console.log(rst);
          this.$message.success('提交成功');
          this.back();
        }).catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },

  }
};
</script>

<style src="../../style.css"></style>
<style scoped>
.head {
    font-size: 20px;
    font-weight: 600;
    color: #292929;
    line-height: 30px;
    padding-bottom: 30px;
}
.list>div {
    font-size: 16px;
    color: #4D4D4D;
    line-height: 20px;
    margin-bottom: 20px;
}
.list>div:last-child {
    margin-bottom: 0;
}
.list>div>div.title {
    width: 90px;
    text-align: right;
    font-size: 14px;
    color: #808080;
    margin-right: 20px;
}
.list .price {
    font-size: 24px;
    color: #EE1818;
}
/* 商品信息 */
.item-right {
    padding:18px 20px;
    background: #FAFAFA;
}
.item-right>div {
    margin-bottom: 20px;
}
.item-right>div:last-child {
    margin-bottom: 0;
}
.item-right>div>div.title {
    width: 60px;
    text-align: right;
    font-size: 14px;
    color: #808080;
    margin-right: 20px;
}
.spec>div {
    height: 16px;
    line-height: 16px;
}
.spec>div:nth-child(3), .spec>div:nth-child(4)>div {
    padding-left: 10px;
    border-left: 1px solid #4D4D4D;
    margin-left: 10px;
}

.item-right>>> input[type=number] {
    padding: 0 0 0 15px;
}

</style>